import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_HERO } from '@src/constants/LeadGenerationConstants/index';
import React from 'react';
import Sde2Hero from '../../../images/sde2/sde2Hero.svg';

export default function FspPlusStylePageHero(props) {
  const { heroTitle, heroDescription, heroSubDescription } = props;

  return (
    <div className="container z-[999] flex w-full flex-col items-center justify-between gap-20 pt-12 md:gap-8 md:pt-20 lg:flex-row">
      <div className="z-[999] flex flex-col items-center gap-8 lg:items-start lg:pb-12">
        <h1
          className="text-center font-bold lg:text-left"
          dangerouslySetInnerHTML={{
            __html: heroTitle,
          }}
        ></h1>
        <div
          className="text-center text-xl text-neutral-200 lg:text-left"
          dangerouslySetInnerHTML={{ __html: heroDescription }}
        ></div>
        <div
          className="text-center text-base text-neutral-300 lg:text-left"
          dangerouslySetInnerHTML={{ __html: heroSubDescription }}
        ></div>
        <LeadGenerationButton
          buttonLocation={LEAD_GEN_HERO}
          className="w-min"
          type="Apply & Start Free"
        />
      </div>

      <div className="z-[999]">
        <Sde2Hero className="h-[300px] w-[350px] scale-[1.15] md:h-[400px] md:w-[600px] md:translate-y-6" />
      </div>
    </div>
  );
}
