import RawHTML from '@components/Common/RawHTML/index';
import React from 'react';

export default function FspPlusPricingCard({
  topTag,
  bottomTag,
  price,
  priceDuration,
  sectionTitle,
  priceHighlights,
  postContent,
  scheme,
}) {
  const getTopTagCss = () => {
    switch (scheme) {
      case 'dark':
        return '';
      case 'gradient':
        return 'bg-v5-green-500 rounded-[5px] text-v5-neutral-200 font-bold p-2';
      default:
        return '';
    }
  };

  const getInnerCardCss = () => {
    switch (scheme) {
      case 'dark':
        return '';
      case 'gradient':
        return 'pricing-sub-card-gradient';
      default:
        return '';
    }
  };

  const getOuterCardCss = () => {
    switch (scheme) {
      case 'dark':
        return '';
      case 'gradient':
        return 'pricing-card-gradient border-gradient border-gradient-radius30';
      default:
        return '';
    }
  };

  return (
    <div className="container relative z-[999] flex flex-col items-center gap-12">
      <RawHTML className="z-[300] text-center font-manrope text-2xl font-extrabold capitalize leading-snug text-v5-neutral-200 md:text-4xl">
        {sectionTitle}
      </RawHTML>

      <div className={`flex flex-col gap-16 ${getOuterCardCss()}`}>
        <div className="flex flex-col items-center justify-evenly gap-8 py-10 px-4 md:flex-row md:gap-16 md:px-14">
          <div
            className={`flex flex-col items-start justify-center gap-4 rounded-[30px] py-10 px-8 md:py-6 md:px-14 ${getInnerCardCss()}`}
          >
            <div className={`text-base ${getTopTagCss()}`}>{topTag}</div>

            <div className="-mt-1">
              <span className="mr-1 text-4xl font-bold text-v5-green-300 md:text-[44px]">
                ₹
              </span>
              <span className="text-4xl font-extrabold text-v5-green-100 md:text-[44px] ">
                {price}
              </span>
              <span className="text-v5-green-300">&nbsp;/ month</span>
              <div className="text-v5-green-300">{priceDuration}</div>
            </div>
            <div className="text-lg font-bold text-v5-neutral-300">
              {bottomTag}
            </div>
          </div>
        </div>
      </div>

      {postContent && postContent}
    </div>
  );
}
