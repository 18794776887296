import RawHTML from '@components/Common/RawHTML/index';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function GlassCard(props) {
  const { card } = props;

  const image = getImage(card?.image);

  return (
    <div className="border-gradient-radius30 border-gradient gradient-card-dark z-[999] flex w-full flex-col items-center justify-start gap-8 rounded-[30px] p-8 backdrop-blur md:max-w-[330px]">
      <div className="flex h-[160px] w-[250px] items-center justify-center">
        <GatsbyImage
          loading="eager"
          image={image}
          placeholder="blurred"
          alt={'company'}
          imgStyle={{ objectFit: 'contain' }}
          className="h-full w-full"
        />
      </div>

      <RawHTML className="text-crio-neutral-200">{card.description}</RawHTML>
    </div>
  );
}

// bg-gradient-to-br from-[rgba(0, 71, 75, 1)] to-[rgba(0, 38, 40, 0.4)]
