import { graphql } from 'gatsby';
import React from 'react';
import SEO from '@components/Common/SEO/index';
import Navigation from '@components/Navigation/index';
import FspPlusStylePageHero from '@components/v5/FspPlusStylePageHero/index';
import GlassHighlights from '@components/GlassMorphism/GlassHighlights/index';
import FooterV2 from '@components/FooterV2/index';
import GlassCards from '@components/GlassMorphism/GlassCards/index';
import RawHTML from '@components/Common/RawHTML/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_CAREER_ASSISTANCE,
  LEAD_GEN_CURRICULUM_PRICING_CARD,
  LEAD_GEN_DEVELOPERS_CRACKED,
  LEAD_GEN_TESTIMONIALS,
  LEAD_GEN_WORK_EXPERIENCE,
} from '@src/constants/LeadGenerationConstants/index';
import CrackedCrioCareersSection from '@components/v5/CrackedCrioCareersSection/index';
import PostContentCTALayout from '@components/v5/layouts/PostContentCTALayout/index';
import { ERoutes } from '@src/constants/routes/index';
import CrioButton from '@components/v5/CrioButton/index';
import { Link } from 'gatsby';
import FspCurriculumSection, {
  CustomAccordion,
} from '@components/v5/FspCurriculumSection/index';
import FspPlusPricingCard from '@components/v5/FspPlusPricingCard/index';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection/index';
import FspVideoSection from '@components/v5/FspVideoSection/index';
import FAQSection from '@components/v5/FAQSection/index';
import AdvancedCurriculum from '@components/v5/FspCurriculumSection/AdvancedCurriculum/index';
import GlassCircleBlur from '@components/GlassMorphism/GlassCircleBlur/index';
import fsdp from '../../seo/jsonld/fsdp.json';
import { getExperimentAndVariant } from '@src/analytics/gtm';
import StatsPanelTemp from '@components/v5/StatsPanelTemp/index';

export default function FspSystemDesign(props) {
  const { data: pageData } = props;
  const { sde2Yaml: data } = pageData;

  const abTestingId = getExperimentAndVariant().variant;

  return (
    <div className="relative">
      <SEO
        title={data.SEOTitle}
        description={data.SEODescription}
        canonicalUrl={data.canonicalUrl}
      >
        <script type="application/ld+json">{JSON.stringify(fsdp)}</script>
      </SEO>

      <Navigation dark />

      {/* Section 0: Static Container Section */}
      <div className="h-[104px] w-full bg-black md:h-[105px]"></div>

      {/* Section 1: Hero Section */}
      <div className="h-full w-full bg-gradient-to-b from-black to-crio-green-1000 text-white">
        <FspPlusStylePageHero
          heroTitle={data.heroTitle}
          heroDescription={data.heroDescription}
          heroSubDescription={data.heroSubDescription}
        />

        <GlassHighlights highlights={data.highlights} />
      </div>

      <div className="h-full w-full bg-v5-green-700">
        {/* Section 2: Work-Ex Section */}
        <div className="container relative py-24">
          <div className="flex flex-col items-center justify-center gap-20 ">
            <RawHTML className="relative z-[999] text-center font-manrope text-2xl font-bold leading-[1.2] text-white md:text-4xl">
              {data.featureSectionTitle}
            </RawHTML>
            <GlassCards cards={data.features} />

            <LeadGenerationButton
              buttonLocation={LEAD_GEN_WORK_EXPERIENCE}
              className="w-min"
              type="Apply & Start Free"
            />
          </div>
        </div>

        {/* Section 3: Developers Section */}
        <CrackedCrioCareersSection
          gradient
          heading={
            <div className="relative z-[999] text-center text-2xl font-bold leading-[1.2] text-white md:text-4xl">
              Developers from Crio
              <br />
              have Cracked Careers in
            </div>
          }
          postContent={
            <PostContentCTALayout>
              <div className="z-[999] -mt-20">
                <div className="mt-20">
                  {/* This is a temporary change:(31 May 2024)
                   * Currently the CTC stats are not available for the FSP System Design Program
                   * The 'StatsPanel' component is not designed to handle the absence of CTC stats
                   * Hence, we are using the StatsPanelTemp component instead of the StatsPanel component
                   * as changing changing design of StatsPanel will affect other pages where it is being used
                   * Replace the StatsPanelTemp component with the StatsPanel component once the CTC stats are available
                   */}
                  {/* <StatsPanel gradient propStats={data.statsFspSystemDesign} /> */}
                  <StatsPanelTemp
                    gradient
                    propStats={data.statsFspSystemDesign}
                  />
                </div>
                <PostContentCTALayout>
                  <Link to={ERoutes.PLACEMENTS}>
                    <CrioButton
                      variant="secondary"
                      className="h-[64px] w-[300px] border-white py-6 text-white sm:w-auto"
                    >
                      Get our Placement Stats
                    </CrioButton>
                  </Link>
                  <LeadGenerationButton
                    type="Apply & Start Free"
                    buttonLocation={LEAD_GEN_DEVELOPERS_CRACKED}
                    className="h-[64px] w-[300px]"
                  />
                </PostContentCTALayout>
              </div>
            </PostContentCTALayout>
          }
        ></CrackedCrioCareersSection>

        <div
          id="buildingdistributedsystem"
          style={{ position: 'relative', scrollMarginTop: '100px' }}
        >
          <FspVideoSection
            dark
            heading={data.videoSectionTitle}
            headingClassName="md:text-4xl text-2xl"
            postContent={
              <PostContentCTALayout className="mt-8 mb-8 sm:mt-20 md:mb-12">
                <LeadGenerationButton
                  type="Apply & Start Free"
                  buttonLocation={LEAD_GEN_CAREER_ASSISTANCE}
                />
              </PostContentCTALayout>
            }
          />
        </div>

        <div className="container flex flex-col gap-8">
          <RawHTML className="relative z-[999] pb-8 text-center font-manrope text-2xl font-bold leading-[1.2] text-white md:text-4xl">
            {data.curriculumSectionTitle}
          </RawHTML>

          <AdvancedCurriculum
            CustomAccordion={CustomAccordion}
            curriculum={data.lldCurriculum}
            forceNoHeading
            gradient
            tag={data.lldSvgTag}
          />

          <AdvancedCurriculum
            CustomAccordion={CustomAccordion}
            curriculum={data.hldCurriculum}
            heading={data.hldCurriculumHeading}
            description={data.hldCurriculumDescription}
            gradient
            tag={data.hldSvgTag}
          />

          <AdvancedCurriculum
            CustomAccordion={CustomAccordion}
            curriculum={data.dsaCurriculum}
            forceNoHeading
            gradient
            tag={data.dsaSvgTag}
          />
        </div>
        <div className="pt-8">
          <FspCurriculumSection
            dark
            gradient
            heading={data.curriculumTitle}
            headingClassName="md:text-4xl text-2xl"
            description={data.curriculumDescription}
            quote={data.curriculumQuote}
            hideCurriculumSection
            isTypeFellowShipPlus
          />
        </div>

        <FspPlusPricingCard
          topTag={'EMI AS LOW AS'}
          bottomTag="Before Scholarship"
          price={data.price}
          priceDuration={data.priceDuration}
          sectionTitle={data.priceSectionTitle}
          priceHighlights={data.priceHighlights}
          scheme="gradient"
          postContent={
            <PostContentCTALayout className="mt-0 sm:mt-4">
              <LeadGenerationButton
                type="Apply & Start Free"
                buttonLocation={LEAD_GEN_CURRICULUM_PRICING_CARD}
              />
            </PostContentCTALayout>
          }
        ></FspPlusPricingCard>

        <VideoTestimonialSection
          isTypeFellowShipPlus
          headingClassName="md:text-4xl text-2xl"
          dark
          gradient
          quote={data.testimonialQuote}
          postContent={
            <PostContentCTALayout>
              <div className="-mt-16 xxs:-mt-10 sm:-mt-0">
                <LeadGenerationButton
                  type="Apply & Start Free"
                  buttonLocation={LEAD_GEN_TESTIMONIALS}
                />
              </div>
            </PostContentCTALayout>
          }
        ></VideoTestimonialSection>
      </div>

      <FAQSection
        gradient
        data={data.faqs}
        headingClassName="md:text-4xl text-2xl"
      ></FAQSection>

      <FooterV2 gradient />

      <div className="pointer-events-none fixed top-0 h-screen w-[calc(100vw-1rem)]">
        <GlassCircleBlur extendedClassName="absolute bg-gradient-to-t from-v5-green-100 to-v5-green-700 right-8 -top-[10%]" />
        <GlassCircleBlur extendedClassName="absolute bg-black top-0 left-[2%]" />

        <GlassCircleBlur extendedClassName="absolute bg-gradient-to-t from-v5-green-100 to-v5-green-700 left-0 top-[55%]" />
        <GlassCircleBlur extendedClassName="absolute bg-black top-[50%] right-8" />
      </div>
    </div>
  );
}

export const pageQuery = graphql`
  query {
    sde2Yaml {
      slug
      canonicalUrl
      SEOTitle
      SEODescription
      PrimaryProgramID

      testimonialQuote

      faqs {
        question
        answer
      }

      price
      priceSectionTitle
      priceDuration

      stats {
        title
        subtitle
      }

      statsFspSystemDesign {
        title
        subtitle
      }

      externshipHeading
      externshipDuration
      externshipDescription
      externshipChips
      externshipSvgTag

      heroDescription
      heroTitle
      heroSubDescription

      highlights {
        description
        svgType
        title
      }

      curriculumSectionTitle
      hldSvgTag
      hldCurriculumHeading
      hldCurriculumDescription
      hldCurriculum {
        title
        skills
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
      }

      lldSvgTag
      lldCurriculum {
        title
        description
        skills
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        cards {
          data
          skills
          title
        }
      }

      dsaSvgTag
      dsaCurriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        cards {
          data
          skills
          title
        }
      }

      curriculumTitle
      curriculumDescription
      curriculumQuote

      videoSectionTitle

      featureSectionTitle
      features {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 400
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      careerTitle
      careerDescription
      careers {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;
