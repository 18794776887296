import RawHTML from '@components/Common/RawHTML/index';
import React from 'react';
import useCrioStat from '@src/hooks/useCrioStats';

const StatsPanelTemp = ({ green, dark, gradient, propStats }) => {
  const { placementStats } = useCrioStat();

  const stats = placementStats.map((stat) => {
    return {
      title: stat.content,
      subtitle: `${stat.label}`,
    };
  });

  const getStats = () => {
    if (propStats && Array.isArray(propStats)) {
      return propStats;
    } else {
      return stats;
    }
  };

  const getCardClass = () => {
    let className =
      'p-4 sm:p-0 flex flex-wrap md:justify-center rounded-[20px] sm:flex-nowrap justify-between md:flex md:min-w-[680px] w-full';

    if (gradient) {
      className = className.concat(
        ' gradient-card-dark border-gradient border-gradient-radius20 shadow-md',
      );
    } else if (dark) {
      className = className.concat(' bg-v5-green-500 text-v5-neutral-200');
    } else if (green) {
      className = className.concat(' bg-v5-neutral-200 text-v5-neutral-400');
    } else {
      className = className.concat(' bg-v5-neutral-200');
    }
    return className;
  };

  return (
    <div className={getCardClass()}>
      {getStats().map((el, idx) => (
        <>
          <div
            key={el.title}
            className={`my-2 flex flex-1 flex-col justify-center px-2 text-center md:w-full`}
          >
            <h4
              className={`${
                gradient
                  ? 'text-v5-green-100'
                  : dark
                  ? 'text-v5-green-100'
                  : 'text-black'
              } font-manrope font-extrabold`}
            >
              {el.title}
            </h4>
            <p
              className={`whitespace-nowrap font-rubik text-xs leading-tight ${
                gradient && 'text-v5-neutral-200'
              }`}
            >
              <RawHTML>{el.subtitle}</RawHTML>
            </p>
          </div>
          {idx !== getStats().length - 1 && (
            <div
              className={`hidden h-full min-h-[100px] border-r ${
                gradient
                  ? 'border-v5-green-200  border-opacity-30'
                  : 'border-v5-neutral-400 border-opacity-20'
              }  sm:block`}
            ></div>
          )}
        </>
      ))}
    </div>
  );
};

export default StatsPanelTemp;
