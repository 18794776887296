import React from 'react';
import GlassCard from './GlassCard/index';

export default function GlassCards(props) {
  const { cards } = props;
  return (
    <div className="z-[999] flex w-full flex-wrap justify-center gap-10">
      {cards.map((card, idx) => (
        <GlassCard card={card} key={`card-${idx}`} />
      ))}
    </div>
  );
}
