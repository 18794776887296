import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import React, { useContext, useEffect } from 'react';
import SectionLayout from '../layouts/SectionLayout/index';

const FspVideoSection = React.memo(function FspVideoSection({
  heading,
  description,
  iframeContainerClassName,
  iframeContainerWrapperClassName,
  containerClassName,
  headingClassName,
  ...props
}) {
  const state = useContext(GlobalStateContext);

  useEffect(() => {
    const listener = window?.addEventListener('blur', () => {
      if (document.activeElement === document.querySelector('iframe')) {
        GTM.track(gtmEvents.CTA_CLICKED, {
          type: 'SDE_PLUS_VIDEO',
          email: state.email,
        });
      }

      window?.removeEventListener('blur', listener);
    });
  }, []);

  return (
    <SectionLayout
      heading={heading}
      description={description}
      headingClassName={headingClassName}
      {...props}
    >
      <div
        className={
          containerClassName
            ? containerClassName
            : 'relative z-[999] ml-4 flex items-center justify-center md:pt-8'
        }
      >
        <div
          className={
            iframeContainerWrapperClassName
              ? iframeContainerWrapperClassName
              : 'aspect-video rounded-2xl bg-black'
          }
        >
          <div
            className={
              iframeContainerClassName
                ? iframeContainerClassName
                : 'flex aspect-[16/9] w-[300px] -translate-x-2 -translate-y-2 rounded-3xl md:w-[640px] md:-translate-x-4 md:-translate-y-4'
            }
          >
            <iframe
              title="sde-plus"
              loading="lazy"
              src={`https://www.youtube.com/embed/rj0rBBNoUDI?rel=0&enablejsapi=1&origin=https%3A%2F%2Fcrio.do`}
              frameBorder="0"
              allowFullScreen="allowFullScreen"
              className="w-full rounded-2xl pb-0"
            />
          </div>
        </div>
      </div>
    </SectionLayout>
  );
});

export default FspVideoSection;
