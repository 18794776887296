import { twMerge } from 'tailwind-merge';
import React from 'react';

export default function PostContentCTALayout({ className, children }) {
  return (
    <section
      className={twMerge(
        `container mt-20 flex w-full flex-wrap items-stretch justify-center gap-x-3 gap-y-3 sm:mt-24 ${
          className ? className : ''
        }`,
      )}
    >
      {children}
    </section>
  );
}
