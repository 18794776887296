import React from 'react';
import InfiniteSlider from '../InfiniteSlider/index';
import { graphql, useStaticQuery } from 'gatsby';
import SectionLayout from '../layouts/SectionLayout/index';
import { shuffle } from 'lodash';

const CrackedCrioCareersSection = React.memo(function CrackedCrioCareersSection(
  props,
) {
  const data = useStaticQuery(graphql`
    query {
      allStrapiGraduates {
        ...allStrapiGraduatesFragment
      }
    }
  `);

  const graduatesData = data.allStrapiGraduates.nodes.reduce((result, obj) => {
    if (
      obj.showInPlacementsSection &&
      obj.company &&
      obj.company.Logo &&
      obj.Photo
    ) {
      result.push({
        name: obj.Name,
        companyLogo: obj.company.Logo,
        profileImage: obj.Photo,
      });
    }

    return result;
  }, []);

  return (
    <SectionLayout
      {...props}
      heading={
        props?.heading ? (
          props.heading
        ) : (
          <>
            Crio Grads<br className="hidden sm:block"></br> have Cracked their
            Dream Careers In
          </>
        )
      }
    >
      <div>
        <div className="mb-4">
          <InfiniteSlider
            dark={props.dark}
            gradient={props.gradient}
            data={shuffle(graduatesData.slice(0, graduatesData.length / 2))}
          ></InfiniteSlider>
        </div>
        <div>
          <InfiniteSlider
            dark={props.dark}
            gradient={props.gradient}
            data={shuffle(graduatesData.slice(graduatesData.length / 2))}
            delay={1000}
          ></InfiniteSlider>
        </div>
      </div>
    </SectionLayout>
  );
});

export default CrackedCrioCareersSection;
